// import logo from '../img/logo.png';
import b1 from '../img/banner1.png';
import s1 from '../img/body-1.png';
import s2 from '../img/body-2.png';
import s3 from '../img/body-3.png';
import i1 from '../img/icons/image-file.png';
import i2 from '../img/icons/send.png';
import i3 from '../img/icons/5061264.png';
import i4 from '../img/icons/product.png';
import a1 from '../img/icons/quality.png';
import a2 from '../img/icons/best-price.png';
import a3 from '../img/icons/shop.png';
import linelogo from '../img/LINE_logo.png';
import telicon from '../img/tel_icon.png';
import emailicon from '../img/email_icon.png';
import './css/body.css';
// import { Helmet } from 'react-helmet';

function Header() {
  return (
    <>
    <div className="couros bg-1">
      <img src={b1} className="banner-1" alt="Yindee Trayang banner" />
      {/* <div style={{ backgroundImage: 'url('+b1+')' }} className="banner-1" alt="Yindee Trayang banner" /> */}
    </div>
    
    <div className="about bg-0">
      <div className="sec">
        <h2>
        คุณกำลังมองหาร้านทำตรายางอยู่ใช่ไหม?<br/>
        <span>เราขอเสนอบริการกับคุณ!</span>
        </h2>
        <div className="intro-container">
          <div className="step">
            <div className="pic pic-3" style={{backgroundImage: 'url('+a3+')'}}></div>
            <div>
              <h2>เรามีหน้าร้านอยู่ในมีนบุรี</h2>
            </div>
          </div>
          <div className="step">
            <div className="pic pic-1" style={{backgroundImage: 'url('+a1+')'}}></div>
            <div>
              <h2>ประสบการณ์ทำตรายางมากว่า 20 ปี</h2>
            </div>
          </div>
          <div className="step">
            <div className="pic pic-2" style={{backgroundImage: 'url('+a2+')'}}></div>
            <div>
              <h2>ราคาที่เป็นกันเอง</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style={{backgroundColor: 'rgb(252 255 252);'}}>
      <div className="sec">
        <h1 style={{marginBottom: '20px'}}>แอดไลน์เพื่อสอบถามได้เลย</h1>
        <div className="line-button">
          <a href="https://line.me/ti/p/VqlECfeZm4" target="_blank" rel="noreferrer">
            <div className="line-button-wrap">
              <img src={linelogo} alt="Lineid" />
              <span>yindee3988</span>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div className="bg-1">
      <div className="sec">
        <h1>ประเภทตรายางที่เรารับทำ</h1>
        <div className="stamp-type">
          <div className="stamp-show">
            <div className="aspect-100">
              <div className="stamp-img" style={{backgroundImage: 'url('+s1+')',}}></div>
            </div>
            <h2>ตรายางเรซิ่น</h2>
            <p>
            <li>ราคาถูก ใช้เวลาทำไม่นาน<br /></li>
            <li>ใช้คู่กับแท่นหมึกประทับ<br /></li>
            <li>เป็นที่นิยมใช้กันทั่วไป<br /></li>
            </p>
          </div>
          <div className="stamp-show">
            <div className="aspect-100">
              <div className="stamp-img" style={{backgroundImage: 'url('+s2+')',}}></div>
            </div>
            <h2>ตรายางหมึกในตัว แบบแฟลช</h2>
            <p>
            <li>ยางคุณภาพดี มีความคมชัด<br /></li>
            <li>ปั้มได้ประมาณ 5000 - 10000 ครั้ง<br /> สามารถเติมหมึกได้<br /></li>
            <li>ขนาดพกพาสะดวก<br /></li>
            </p>
          </div>
          <div className="stamp-show">
            <div className="aspect-100">
              <div className="stamp-img" style={{backgroundImage: 'url('+s3+')',}}></div>
            </div>
            <h2>ตรายางหมึกในตัว แบบตลับหมึกพลิก</h2>
            <p>
            <li>มีตลับหมึกในตัว<br /></li>
            <li>ไม่จำเป็นต้องปิดฝา เพราะตัวยางจะ<br />ถูกเก็บเข้าไปในด้ามเมื่อใช้เสร็จ<br /></li>
            <li>วัสดุที่ใช้มีคุณภาพสูง<br /></li>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="howto">
      <div className="sec">
        <h1>วิธีสั่งทำ</h1>
        <div className="howto-container">
          <div className="step">
            <div className="pic pic-1" style={{backgroundImage: 'url('+i1+')'}}></div>
            <div>
              <h2>เตรียมแบบที่ต้องการ</h2>
              <p>สามารถเป็นไฟล์รูปถ่าย หรือไฟล์ต้นฉบับได้ (.pdf, .ai, .ps, etc.)</p>
            </div>
          </div>
          <div className="step">
            <div className="pic pic-2" style={{backgroundImage: 'url('+i2+')'}}></div>
            <div>
              <h2>ส่งข้อมูลมาให้ทางร้าน</h2>
              <p>
                - แบบของตรายาง<br />
                - ตรายางธรรมดา / หมึกในตัว<br />
                - ขนาดที่ต้องการ
              </p>
            </div>
          </div>
          <div className="step">
            <div className="pic pic-3" style={{backgroundImage: 'url('+i3+')'}}></div>
            <div>
              <h2>ตรวจสอบ และจ่ายเงิน</h2>
              <p>หลังจากทางร้านได้รับข้อมูลแล้ว จะมีการส่งแบบกลับไปเพื่อให้คุณลูกค้ายืนยันและชำระเงิน ก่อนจะเริ่มทำ</p>
            </div>
          </div>
          <div className="step">
            <div className="pic pic-4" style={{backgroundImage: 'url('+i4+')'}}></div>
            <div>
              <h2>รับของ</h2>
              <p>ท่านสามารถให้ทางร้านส่งตรายางไปยังที่อยู่ที่ท่านให้ไว้ หรือสามารถเข้ามารับที่หน้าร้านได้</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="contact-container  bg-1">
      <div className="sec">
        <h1>ท่านสามารถทักทาย และสอบถามรายละเอียดได้ที่</h1>
        <div className="contact-row">
          {/* <div className="aspect-22">
            <a href="https://line.me/ti/p/VqlECfeZm4" target="_blank" rel="noreferrer"><div className="contact-box" style={{backgroundImage: 'url('+c1+')'}}></div></a>
          </div>
          <div className="aspect-22">
            <a href="mailto:surasakyindee@gmail.com"><div className="contact-box" style={{backgroundImage: 'url('+c2+')'}}></div></a>
          </div>
          <div className="aspect-22">
            <a href="tel:0891729963"><div className="contact-box" style={{backgroundImage: 'url('+c3+')'}}></div></a>
          </div> */}
            <div className="contact-button line">
              <a href="https://line.me/ti/p/VqlECfeZm4" target="_blank" rel="noreferrer">
                <div className="contact-button-wrap">
                  <img src={linelogo} alt="Lineid" />
                  <span>yindee3988</span>
                </div>
              </a>
            </div>
            <div className="contact-button tel">
              <a href="https://line.me/ti/p/VqlECfeZm4" target="_blank" rel="noreferrer">
                <div className="contact-button-wrap">
                  <img src={telicon} alt="Lineid" />
                  <span>089-172-9963</span>
                </div>
              </a>
            </div>
            <div className="contact-button email">
              <a href="https://line.me/ti/p/VqlECfeZm4" target="_blank" rel="noreferrer">
                <div className="contact-button-wrap">
                  <img src={emailicon} alt="Lineid" />
                  <span>surasakyindee@gmail.com</span>
                </div>
              </a>
            </div>
        </div>
        <div className="map-bubble">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.436610229804!2d100.72076261542782!3d13.812801299597032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d64639d3d7e77%3A0xab110e9362595c84!2sYindee%20Printing!5e0!3m2!1sen!2sth!4v1635162146032!5m2!1sen!2sth"
            width="100%"
            height="100%"
            title="Google map Yindeeprinting"
            style={{border:'0'}}
            allowFullScreen
            loading="lazy">
          </iframe>
        </div>
      </div>
    </div>
    </>
  );
}

export default Header;
