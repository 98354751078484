import React from 'react'
import Header from './components/header';
import Landing from './components/landing';
import Footer from './components/footer';
import Howto from './components/howto';
import Contact from './components/contact';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

export default function App() {
  return (
    <Router>
      <div>
        <Header />
        <Switch>
          <Route path="/การติดต่อ">
            <Contact />
          </Route>
          <Route path="/วิธีสั่งทำ">
            <Howto />
          </Route>
          <Route path="/%E0%B8%A7%E0%B8%B4%E0%B8%98%E0%B8%B5%E0%B8%AA%E0%B8%B1%E0%B9%88%E0%B8%87%E0%B8%97%E0%B8%B3">
            <Contact />
          </Route>
          <Route path="/%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%95%E0%B8%B4%E0%B8%94%E0%B8%95%E0%B9%88%E0%B8%AD">
            <Howto />
          </Route>
          <Route path="/">
            <Landing />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}