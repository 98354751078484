import logo from '../img/logo.png';
import linelogo from '../img/LINE_logo.png';
import telicon from '../img/tel_icon.png';
import './css/header.css';
import { Link } from "react-router-dom";
// import Navbar from 'react-bootstrap/Navbar'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Container, Nav } from 'react-bootstrap';

function Header() {
  return (
    <>
    <Navbar expand="lg" className="md">
      <Container>
        <Navbar.Brand href="./.."><img src={logo} className="small-logo" alt="logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link href="/ตรายางเรซิ่น">ตรายางเรซิ่น</Nav.Link>
            <Nav.Link href="/ตรายางหมึกในตัว">ตรายางหมึกในตัว</Nav.Link> */}
            <Nav.Link href="/">หน้าหลัก</Nav.Link>
            <Nav.Link href="/วิธีสั่งทำ">วิธีสั่งทำ</Nav.Link>
            <Nav.Link href="/การติดต่อ">การติดต่อ</Nav.Link>
            <div className="contact-collapse">
              <a href="https://line.me/ti/p/VqlECfeZm4" target="_blank" rel="noreferrer"><img src={linelogo} className="header-icon i2" alt="Lineid" /><span className="c2 header-con">yindee3988</span></a>
              <a href="tel:0891729963"><img src={telicon} className="header-icon i1" alt="Tel" /><span className="c1 header-con">089-172-9963</span></a>
            </div>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    <div className="hd">
      <div className="header">
        <Link to="/"><img src={logo} className="logo" alt="logo" /></Link>
        <div className="contact">
          <a href="https://line.me/ti/p/VqlECfeZm4" target="_blank" rel="noreferrer"><img src={linelogo} className="header-icon i2" alt="Lineid" /><span className="c2 header-con">yindee3988</span></a>
          <a href="tel:0891729963"><img src={telicon} className="header-icon i1" alt="Tel" /><span className="c1 header-con">089-172-9963</span></a>
        </div>
      </div>
      <div className="nav">
        <div className="nav-inside">
          {/* <Link to="/ตรายางเรซิ่น"><span className="n1">ตรายางเรซิ่น</span></Link>
          <Link to="/ตรายางหมึกในตัว"><span className="n1">ตรายางหมึกในตัว</span></Link> */}
          <Link to="/"><span className="n1">หน้าหลัก</span></Link>
          <Link to="/วิธีสั่งทำ"><span className="n2">วิธีสั่งทำ</span></Link>
          <Link to="/การติดต่อ"><span className="n3">การติดต่อ</span></Link>
        </div>
      </div>
    </div>
    </>
  );
}

export default Header;
