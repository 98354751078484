import logo from '../img/logo.png';
import './css/footer.css';

function Footer() {
  return (
    <>
    <div className="footer">
      <div className="wrap">
        <div className="col-1">
          <img src={logo} className="logo" alt="logo" />
          <p>
            <a href="/">หน้าหลัก</a>
          </p>
          <p>
            <a href="/วิธีสั่งทำ">วิธีสั่งทำ</a>
          </p>
          <p>
            <a href="/การติดต่อ">การติดต่อ</a>
          </p>
        </div>
        <div className="col-2">
          <span>Tel : <a href="tel:0891729963">089-172-9963</a></span>
          <span>Line id : <a href="https://line.me/ti/p/VqlECfeZm4" target="_blank" rel="noreferrer">yindee3988</a></span>
          <span>Email : <a href="mailto:surasakyindee@gmail.com">surasakyindee@gmail.com</a></span>
          <span style={{marginTop: '10px'}}>
          Address : 64/6 ซ.สีหบุรานุกิจ 3 ถนนสีหบุรานุกิจ
          แขวงมีนบุรี เขตมีนบุรี กรุงเทพมหานคร 10510
          </span>
        </div>
      </div>
      <div className="wrap">
        <p>@ 2021 Yindeekarnpim - All rights reserved.</p>
      </div>
    </div>
    </>
  );
}

export default Footer;
