// import logo from '../img/logo.png';
import b1 from '../img/banner-contact.png';
import linelogo from '../img/LINE_logo.png';
import telicon from '../img/tel_icon.png';
import emailicon from '../img/email_icon.png';
import './css/body.css';
import React,{ useEffect } from 'react';


function Contact() {
  useEffect(() => {
    document.title = "ยินดีการพิมพ์ บริการรับทำตรายางด่วน ตรายางหมึกในตัว มีนบุรี | การติดต่อ"
  }, []);
  return (
    <>
    <div className="couros bg-1">
      <img src={b1} className="banner-1" alt="Yindee Trayang banner" />
    </div>
    <div className="">
    <div className="contact-container">
      <div className="sec">
        <h1>
        เรามั่นใจในการให้บริการของเรา ได้รับความไว้วางใจจากลูกค้าในพื้นที่<br />
        ด้วยประสบการณ์การให้บริการมากว่า 20 ปี<br />
        </h1>
      </div>
    </div>
    </div>
    <div className="bg-1">
      <div className="sec">
          <h1>สามารถติดต่อเราได้ที่</h1>
        <div className="contact-bubble">
          <div className="contact-bubble-row">
            <span>Line id :</span>
            <span><a href="https://line.me/ti/p/VqlECfeZm4" target="_blank" rel="noreferrer">yindee3988</a></span>
          </div>
          <div className="contact-bubble-row">
            <span>Email   :</span>
            <span><a href="mailto:surasakyindee@gmail.com">surasakyindee@gmail.com</a></span>
          </div>
          <div className="contact-bubble-row">
            <span>Tel     :</span>
            <span><a href="tel:0891729963"> 089-172-9963</a></span>
          </div>
          <div className="contact-bubble-row">
            <span>ที่อยู่    :</span>
            <span><a href="https://goo.gl/maps/ALUPCQFf797PRrgU8" target="_blank" rel="noreferrer"> 64/6 ซ.สีหบุรานุกิจ 3 ถนนสีหบุรานุกิจ แขวงมีนบุรี เขตมีนบุรี กรุงเทพมหานคร</a></span> 
          </div>
        </div>
        <div className="map-bubble">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.436610229804!2d100.72076261542782!3d13.812801299597032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d64639d3d7e77%3A0xab110e9362595c84!2sYindee%20Printing!5e0!3m2!1sen!2sth!4v1635162146032!5m2!1sen!2sth"
            width="100%"
            height="100%"
            title="Google map Yindeeprinting"
            style={{border:'0'}}
            allowFullScreen
            loading="lazy">
          </iframe>
        </div>
      </div>
    </div>
    <div className="">
    <div className="contact-container">
      <div className="sec">
        <h1>ท่านสามารถทักทาย และสอบถามรายละเอียดได้ที่</h1>
        <div className="contact-row">
            <div className="contact-button line">
              <a href="https://line.me/ti/p/VqlECfeZm4" target="_blank" rel="noreferrer">
                <div className="contact-button-wrap">
                  <img src={linelogo} alt="Lineid" />
                  <span>yindee3988</span>
                </div>
              </a>
            </div>
            <div className="contact-button tel">
              <a href="https://line.me/ti/p/VqlECfeZm4" target="_blank" rel="noreferrer">
                <div className="contact-button-wrap">
                  <img src={telicon} alt="Lineid" />
                  <span>089-172-9963</span>
                </div>
              </a>
            </div>
            <div className="contact-button email">
              <a href="https://line.me/ti/p/VqlECfeZm4" target="_blank" rel="noreferrer">
                <div className="contact-button-wrap">
                  <img src={emailicon} alt="Lineid" />
                  <span>surasakyindee@gmail.com</span>
                </div>
              </a>
            </div>
        </div>
      </div>
    </div>
    </div>
    </>
  );
}

export default Contact;
