// import logo from '../img/logo.png';
import b1 from '../img/banner-howto.png';
import i1 from '../img/icons/image-file.png';
import i2 from '../img/icons/send.png';
import i3 from '../img/icons/5061264.png';
import i4 from '../img/icons/product.png';
import h1 from '../img/example-1.png';
import linelogo from '../img/LINE_logo.png';
import telicon from '../img/tel_icon.png';
import emailicon from '../img/email_icon.png';
import './css/body.css';
import './css/howto.css';
import React,{ useEffect } from 'react';


function Header() {
  useEffect(() => {
    document.title = "ยินดีการพิมพ์ บริการรับทำตรายางด่วน ตรายางหมึกในตัว มีนบุรี | วิธีสั่งทำ"
  }, []);
  return (
    <>
    <div className="couros bg-1">
      <img src={b1} className="banner-1" alt="Yindee Trayang banner" />
    </div>
    <div className="howto">
      <div className="sec">
        <h1>วิธีสั่งทำ</h1>
        <div className="howto-container p2">
          <div className="step">
            <div className="pic pic-1" style={{backgroundImage: 'url('+i1+')'}}></div>
            <div>
              <h2>เตรียมแบบที่ต้องการ</h2>
              <p>สามารถเป็นไฟล์รูปถ่าย หรือไฟล์ต้นฉบับได้ (.pdf, .ai, .ps, etc.)<br />
              <span style={{color: '#ed4444'}}>*หากเป็นไฟล์รูปถ่าย เพื่อความแม่นยำของแบบ จะต้องนำไม้บรรทัดมาวางทาบตัวแบบเพื่อให้ช่างใช้เป็นตัววัดสเกล</span></p>
              <div className="howto-example" style={{backgroundImage: 'url('+h1+')'}}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="howto bg-1">
      <div className="sec">
        <div className="howto-container p2">
          <div className="step">
            <div className="pic pic-2" style={{backgroundImage: 'url('+i2+')'}}></div>
            <div>
              <h2>ส่งข้อมูลมาให้ทางร้าน</h2>
              <p>
                โดยแนบข้อมูลดังนี้<br />
                <li> แบบของตรายาง<br /></li>
                <li> ขนาดที่ต้องการ<br /></li>
                <li> ชนิดของตรายาง<br /></li>
                ตรายางเรซิ่น / ตรายางหมึกในตัว แบบแฟลช / ตรายางหมึกในตัว แบบตลับหมึกพลิก<br />
              </p>
              <h2>
                มายังช่องทางที่ท่านสะดวก</h2>
              <p>
                <li><a href="https://line.me/ti/p/VqlECfeZm4" target="_blank" rel="noreferrer"> Line : yindee3988</a><br /></li>
                <li><a href="mailto:surasakyindee@gmail.com">Email : surasakyindee3988@gmail.com</a><br /></li>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="howto">
      <div className="sec">
        <div className="howto-container p2">
          <div className="step">
            <div className="pic pic-3" style={{backgroundImage: 'url('+i3+')'}}></div>
            <div>
              <h2>ตรวจสอบและจ่ายเงิน</h2>
              <p>หลังจากทางร้านได้รับข้อมูลแล้ว จะนำมาดราฟเป็นแบบเพื่อไปทำเป็นตรายาง
              <br />แล้วจะส่งแบบกลับไปเพื่อให้คุณลูกค้ายืนยันและชำระเงิน ก่อนจะเริ่มทำ</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="howto bg-1">
      <div className="sec">
        <div className="howto-container p2">
          <div className="step">
            <div className="pic pic-4" style={{backgroundImage: 'url('+i4+')'}}></div>
            <div>
              <h2>รับของ</h2>
              <p>ท่านสามารถให้ทางร้านส่งตรายางไปยังที่อยู่ที่ท่านให้ไว้<br />หรือสามารถเข้ามารับที่หน้าร้านได้</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="contact-container">
      <div className="sec">
        <h1>ท่านสามารถทักทาย และสอบถามรายละเอียดได้ที่</h1>
        <div className="contact-row">
            <div className="contact-button line">
              <a href="https://line.me/ti/p/VqlECfeZm4" target="_blank" rel="noreferrer">
                <div className="contact-button-wrap">
                  <img src={linelogo} alt="Lineid" />
                  <span>yindee3988</span>
                </div>
              </a>
            </div>
            <div className="contact-button tel">
              <a href="https://line.me/ti/p/VqlECfeZm4" target="_blank" rel="noreferrer">
                <div className="contact-button-wrap">
                  <img src={telicon} alt="Lineid" />
                  <span>089-172-9963</span>
                </div>
              </a>
            </div>
            <div className="contact-button email">
              <a href="https://line.me/ti/p/VqlECfeZm4" target="_blank" rel="noreferrer">
                <div className="contact-button-wrap">
                  <img src={emailicon} alt="Lineid" />
                  <span>surasakyindee@gmail.com</span>
                </div>
              </a>
            </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Header;
